import "./App.css";
import Home from "./pages/Home";
import "./bootstrap/css/bootstrap.css";
import React, {lazy, Suspense} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch,} from "react-router-dom";
import PaintingDetail from "./pages/PaintingDetail";
import TechniqueDetail from "./pages/TechniqueDetail";
import {ProvideAppState} from "./providers/ProvideAppState";
import {ProvideAuth, useAuth} from "./providers/ProvideAuth";
import About from "./pages/About";
import {Helmet} from "react-helmet";
import Contact from "./pages/Contact";
import SuspenseFallback from "./components/SuspenseFallback";
import {ProvideScrollMemory} from "./providers/ProvideScrollMemory";
import ProtectedRoute from "./components/ProtectedRoute";
import {ViewMode} from "./model/ViewMode";
import SeriesOverview from "./pages/SeriesOverview";
import SeriesDetail from "./pages/SeriesDetail";
import Prices from "./pages/Prices";
import Analytics from "./components/Analytics";
import {ProvideNavigation} from "./providers/NavigationProvider";

const SeriesEdit = lazy(() => import("./pages/SeriesEdit"));
const PaintingEdit = lazy(() => import("./pages/PaintingEdit"));
const Login = lazy(() => import("./pages/Login"));
const Imprint = lazy(() => import("./pages/Imprint"));

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Farben Formen Fantasien</title>
      </Helmet>
      <Router>
        <ProvideAuth>
          <AuthenticatedApp/>
        </ProvideAuth>
      </Router>
    </div>
  );
}

function AuthenticatedApp() {
  const isAuthenticated = !!useAuth().user;
  return !isAuthenticated ? (
    <SuspenseFallback/>
  ) : (
    <Suspense fallback={<SuspenseFallback/>}>
      <ProvideAppState>
        <ProvideScrollMemory>
          <ProvideNavigation>
            <Switch>
              <Route
                exact
                path="/paintings/:technique"
                component={TechniqueDetail}
              />
              <ProtectedRoute
                exact
                path="/painting/new"
                component={() => <PaintingEdit viewMode={ViewMode.NEW}/>}
              />
              <Route exact path="/painting/:id" component={PaintingDetail}/>
              <ProtectedRoute
                exact
                path="/painting/:id/edit"
                component={() => <PaintingEdit viewMode={ViewMode.EDIT}/>}
              />

              <Route exact path="/series" component={SeriesOverview}/>
              <ProtectedRoute
                exact
                path="/series/new"
                component={() => <SeriesEdit viewMode={ViewMode.NEW}/>}
              />
              <Route exact path="/series/:id" component={SeriesDetail}/>
              <ProtectedRoute
                exact
                path="/series/:id/edit"
                component={() => <SeriesEdit viewMode={ViewMode.EDIT}/>}
              />

              <Route exact path="/about" component={About}/>
              <Route exact path="/contact" component={Contact}/>
              <Route exact path="/prices" component={Prices}/>
              <Route exact path="/imprint" component={Imprint}/>

              <Route exact path="/login" component={Login}/>
              <Route exact path="/" component={Home}/>
              <Redirect from="*" to="/"/>
            </Switch>
          </ProvideNavigation>
        </ProvideScrollMemory>
        <Analytics/>
      </ProvideAppState>
    </Suspense>
  );
}

export default App;
