import Painting from "../model/Painting";
import Series from "../model/Series";
import { Link, useParams } from "react-router-dom";
import "./PaintingDetail.css";
import InnerHeader from "../components/InnerHeader";
import React from "react";
import { useAppState } from "../providers/ProvideAppState";
import { useAuth } from "../providers/ProvideAuth";
import { useScrollMemory } from "../providers/ProvideScrollMemory";
import { useNavigation } from "../providers/NavigationProvider";

function PaintingDetail() {
  const appState = useAppState();
  const auth = useAuth();
  const urlId = useParams<{ id: string }>();
  const navigation = useNavigation();
  const scrollMemory = useScrollMemory();

  const [painting, setPainting] = React.useState<Painting>({
    id: "",
    title: "",
    description: null,
    techniques: [],
    year: 0,
    width: 0,
    height: 0,
    status: "Nicht käuflich",
    path: "",
    fallbackPath: "",
    thumbnailPath: "",
  });

  React.useEffect(() => window.scrollTo(0, 0), [painting]);
  React.useEffect(() => scrollMemory.setActivePainting(painting), [painting]);

  React.useEffect(() => {
    if (!urlId.id) {
      navigation.goHome();
    } else {
      const tempPainting = appState.getPaintingById(urlId.id);
      if (!tempPainting) {
        navigation.goHome();
      } else {
        setPainting(Object.assign({}, tempPainting));
      }
    }
  }, []);

  return (
    <>
      <InnerHeader
        showBackButton
        showEditButton={!auth.user?.isAnonymous}
        heading={painting.title}
        useHeadingAsPageTitleOnly
      />
      <div className="container" id="paintingDetail">
        <div className="row justify-content-around">
          <div className="col-lg-6 mb-5">
            <picture key="painting_image">
              <source srcSet={painting.path} type="image/webp" />
              <img
                src={painting.fallbackPath}
                alt={painting.title}
                loading="lazy"
                style={{ maxHeight: "40rem" }}
                className="mw-100 paintingShadow"
              />
            </picture>
            {!auth.user?.isAnonymous ? (
              <><br/>
              <a href={painting.fallbackPath} target="_blank">
                <button className={"btn btn-primary w-auto mt-2"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-cloud-arrow-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z"
                    />
                    <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                  </svg>
                  &nbsp;Download
                </button>
              </a></>
            ) : (
              <></>
            )}
          </div>
          <div className="col-lg-5 mb-5">
            <div className="p-5 shadow rounded bg-light">
              <h2>{painting.title}</h2>
              <hr />
              <div className="paintingInfoGrid">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-brush"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.067 6.067 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.117 8.117 0 0 1-3.078.132 3.658 3.658 0 0 1-.563-.135 1.382 1.382 0 0 1-.465-.247.714.714 0 0 1-.204-.288.622.622 0 0 1 .004-.443c.095-.245.316-.38.461-.452.393-.197.625-.453.867-.826.094-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.2-.925 1.746-.896.126.007.243.025.348.048.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.175-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04zM4.705 11.912a1.23 1.23 0 0 0-.419-.1c-.247-.013-.574.05-.88.479a11.01 11.01 0 0 0-.5.777l-.104.177c-.107.181-.213.362-.32.528-.206.317-.438.61-.76.861a7.127 7.127 0 0 0 2.657-.12c.559-.139.843-.569.993-1.06a3.121 3.121 0 0 0 .126-.75l-.793-.792zm1.44.026c.12-.04.277-.1.458-.183a5.068 5.068 0 0 0 1.535-1.1c1.9-1.996 4.412-5.57 6.052-8.631-2.591 1.927-5.566 4.66-7.302 6.792-.442.543-.796 1.243-1.042 1.826a11.507 11.507 0 0 0-.276.721l.575.575zm-4.973 3.04l.007-.005a.031.031 0 0 1-.007.004zm3.582-3.043l.002.001h-.002z" />
                  </svg>
                  {painting.techniques.join(", ")}
                </div>

                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-aspect-ratio"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5v-9zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
                    <path d="M2 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H3v2.5a.5.5 0 0 1-1 0v-3zm12 7a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H13V8.5a.5.5 0 0 1 1 0v3z" />
                  </svg>
                  {painting.height}x{painting.width}cm
                </div>

                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-watch"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.5 5a.5.5 0 0 0-1 0v2.5H6a.5.5 0 0 0 0 1h2a.5.5 0 0 0 .5-.5V5z" />
                    <path d="M5.667 16C4.747 16 4 15.254 4 14.333v-1.86A5.985 5.985 0 0 1 2 8c0-1.777.772-3.374 2-4.472V1.667C4 .747 4.746 0 5.667 0h4.666C11.253 0 12 .746 12 1.667v1.86a5.99 5.99 0 0 1 1.918 3.48.502.502 0 0 1 .582.493v1a.5.5 0 0 1-.582.493A5.99 5.99 0 0 1 12 12.473v1.86c0 .92-.746 1.667-1.667 1.667H5.667zM13 8A5 5 0 1 0 3 8a5 5 0 0 0 10 0z" />
                  </svg>
                  {painting.year}
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-cash-stack"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                    <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z" />
                  </svg>
                  {painting.status}
                </div>
              </div>
            </div>
            {painting.description ? (
              <div
                className={"p-5 shadow rounded mt-5 paintingInfoFont bg-light"}
              >
                <p className={"mb-0"}>{`„${painting.description}“`}</p>
              </div>
            ) : (
              <></>
            )}
            {painting.seriesId ? (
              <div
                className={"p-5 shadow rounded mt-5 paintingInfoFont bg-light"}
              >
                <p className={"mb-0"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    className="bi bi-folder2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v7a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 12.5v-9zM2.5 3a.5.5 0 0 0-.5.5V6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5zM14 7H2v5.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V7z" />
                  </svg>
                  &nbsp;Teil der Serie&nbsp;
                  <Link to={`/series/${painting.seriesId}`}>
                    <span
                      style={{ color: "#8e3a3a", textDecoration: "underline" }}
                    >
                      „
                      {
                        (
                          appState.series.find(
                            (series) => series.id === painting.seriesId
                          ) as Series
                        ).name
                      }
                      “
                    </span>
                  </Link>
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PaintingDetail;
