import { Link } from "react-router-dom";
import "./BottomLinks.css";
import "../App.css";
import React from "react";

function BottomLinks() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-6 m-auto">
          <nav className="d-sm-flex justify-content-around navigator">
            <Link to="/about">Über mich</Link>
            <Link to="/contact">Kontakt</Link>
            <Link to="/prices">Preise</Link>
            <Link to="/imprint">Impressum</Link>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default BottomLinks;
