import "./PaintingCard.css";
import Painting from "../model/Painting";
import { Link } from "react-router-dom";
import React from "react";
import Series from "../model/Series";
import "./PaintingGrid.css";

function PaintingCard(props: {
  activePainting?: boolean;
  painting: Painting;
  seriesAndCallback?: {
    series: Series;
    clickCallback: (paintingId: string) => void;
  };
}) {
  React.useEffect(() => {
    if (props.activePainting) {
      document.getElementsByClassName("activePainting")[0]?.scrollIntoView();
    }
  });

  const baseContent = (
    <>
      <div className="paintingImageWrapper">
        <picture>
          <source srcSet={props.painting.thumbnailPath} type="image/webp" />
          <img src={props.painting.fallbackPath} alt={props.painting.title} loading="lazy"  className="paintingShadow"/>
        </picture>
      </div>
      <div className="paintingCardTitle">
        <span>{props.painting.title}</span>
      </div>
    </>
  );

  if (props.seriesAndCallback) {
    const selectState =
      props.painting.seriesId === props.seriesAndCallback.series.id
        ? 1 // Selected
        : props.painting.seriesId !== undefined &&
          props.painting.seriesId !== null &&
          props.painting.seriesId !== props.seriesAndCallback.series.id
        ? -1 // Unselectable
        : 0; // Unselected

    return (
      <div>
        <button
          className={`btn ${
            selectState === -1
              ? "paintingUnselectable"
              : selectState === 1
              ? "paintingSelected"
              : ""
          }`}
          onClick={() =>
            props.seriesAndCallback?.clickCallback(props.painting.id)
          }
          disabled={selectState === -1}
        >
          {baseContent}
        </button>
      </div>
    );
  } else {
    return (
      <div className={props.activePainting ? "activePainting" : ""}>
        <Link to={`/painting/` + props.painting.id}>{baseContent}</Link>
      </div>
    );
  }
}

export default PaintingCard;
