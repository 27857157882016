import React from "react";

function SuspenseFallback() {
  return (
    <div className={"container"}>
      <div className="d-flex justify-content-center m-5">
        <div className="spinner-border ml-auto spinnerPrimary" role="status"/>
        <strong className="mx-1 my-1">Lade...</strong>
      </div>
    </div>
  );
}

export default SuspenseFallback;