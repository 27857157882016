import Series from "../model/Series";
import Painting from "../model/Painting";
import InnerHeader from "../components/InnerHeader";
import PaintingGrid from "../components/PaintingGrid";
import { useParams } from "react-router-dom";
import React from "react";
import { useAuth } from "../providers/ProvideAuth";
import { useAppState } from "../providers/ProvideAppState";
import { useNavigation } from "../providers/NavigationProvider";

function SeriesDetail() {
  const appState = useAppState();
  const urlId = useParams<{ id: string }>();
  const auth = useAuth();
  const navigation = useNavigation();

  const [series, setSeries] = React.useState<Series>({
    id: "",
    name: "",
    description: null,
  });

  const [paintings, setPaintings] = React.useState<Painting[]>(
    appState.paintings
  );

  React.useEffect(() => {
    if (!urlId.id) {
      navigation.goHome();
    } else {
      const tempSeries = appState.getSeriesById(urlId.id);
      if (!tempSeries) {
        navigation.goHome();
      } else {
        setSeries(Object.assign({}, tempSeries));
        setPaintings(appState.getPaintingsBySeriesId(tempSeries.id));
      }
    }
  }, []);

  let content = (
    <div
      className="p-5 shadow rounded mt-2 bg-light w-auto"
      style={{ gridColumn: "1/-1" }}
    >
      <h1>{series.name}</h1>
      {series.description ? (
        <>
          <hr />
          <h4 className="fst-italic text-secondary">{series.description}</h4>
        </>
      ) : (
        <></>
      )}
    </div>
  );

  return (
    <>
      <InnerHeader
        showBackButton
        showEditButton={!auth.user?.isAnonymous}
        heading={series.name}
        useHeadingAsPageTitleOnly
      />
      <PaintingGrid paintings={paintings}>{content}</PaintingGrid>
    </>
  );
}

export default SeriesDetail;
