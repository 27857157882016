import {useEffect, useState} from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { useAppState } from "../providers/ProvideAppState";
import { Link } from "react-router-dom";

export enum Consent {
  NONE,
  FUNCTIONAL,
  ANALYTICS,
}

function Analytics() {
  const { cookieConsent, setCookieConsent } = useAppState();

  const [analyticsChecked, setAnalyticsChecked] =
    useState<boolean>(false);

  const handleClick = () => {
    setAnalyticsChecked(!analyticsChecked);
  };

  const initializeAnalytics = (): void => {
    ReactGA.initialize("G-8RB235STNJ", {
      testMode: process.env.NODE_ENV !== "production",
    });
  };

  useEffect(() => {
    if (cookieConsent === Consent.ANALYTICS) {
      initializeAnalytics();
    }
  }, [cookieConsent]);

  const location = useLocation();
  useEffect(() => {
    if (cookieConsent === Consent.ANALYTICS) {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "PageView" });
    }
  }, [cookieConsent, location]);

  const acceptAll = () => {
    setCookieConsent(Consent.ANALYTICS);
  };

  const saveSelection = () => {
    setCookieConsent(analyticsChecked ? Consent.ANALYTICS : Consent.FUNCTIONAL);
  };

  if (cookieConsent === Consent.NONE) {
    return (
      <div className={"cookieBanner"}>
        <div className="d-flex align-items-center">
          <p className="text-left mb-0">Diese Website verwendet Cookies und Google Analytics.&nbsp;<Link to={"/imprint"} className="text-white text-decoration-underline">Weitere Informationen</Link></p>
        </div>
        <div className="d-flex">
          <div className="d-inline-flex align-items-center flex-wrap">
            <div className="pe-2 d-flex justify-content-start">
              <input
                className="form-check-input"
                type="checkbox"
                value={"required"}
                name={"cookies"}
                checked
                disabled
              />&nbsp;
              <label
                className="form-check-label"
                title="Unbedingt notwendige Cookies um die Funktionalität der Website zu gewährleisten."
              >
                Funktional
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={handleClick}
                checked={analyticsChecked}
                name={"cookies"}
              />&nbsp;
              <label
                className="form-check-label"
                title="Cookies für Google Analytics"
              >
                Analyse
              </label>
            </div>
          </div>
          <div className="d-inline-flex">
            <button
              className="btn btn-sm analyticsButtonDecline"
              onClick={saveSelection}
            >
              Auswahl speichern
            </button>
            <button
              className="btn btn-sm analyticsButtonAccept ms-2" style={{borderRadius: 0}}
              id="analytics"
              onClick={acceptAll}
            >
              Alle akzeptieren
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default Analytics;
