import InnerHeader from "../components/InnerHeader";
import React from "react";

function Prices() {
  React.useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <InnerHeader heading="Preise" showBackButton />
      <div className="container">
        <div className="row">
          <div className="col m-auto" style={{ textAlign: "center" }}>
            <p>Die Preise der Bilder bewegen sich zwischen 250 und 450 &euro;. Details auf Anfrage.</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Prices;
