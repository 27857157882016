import Painting from "../model/Painting";
import Series from "../model/Series";
import React, { useContext } from "react";
import { useToasts } from "react-toast-notifications";
import { Technique } from "../model/Technique";
import {
  FirebaseError,
  UploadMetadata,
  firestore,
  storage,
  functions,
} from "../firebase/firebase";
import { basename, extname } from "path-browserify";
import SuspenseFallback from "../components/SuspenseFallback";
import { Consent } from "../components/Analytics";
import Cookies from "js-cookie";

const IMAGE_UPLOAD_METADATA: UploadMetadata = {
  cacheControl: "public, max-age=31536000",
};

const hashString = (string: string): number => {
  let hash = 0;
  if (string.length === 0) return hash;
  for (let i = 0; i < string.length; i++) {
    let char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

const appStateContext = React.createContext<
  ReturnType<typeof useProvideAppState>
>({
  paintings: [],
  series: [],
  cookieConsent: Consent.NONE,
  webpSupport: false,
  setCookieConsent: (_consent) => {},
  createPainting: async (_painting: Painting, _file: File) => Promise.reject(),
  getPaintingById: (_id: string) => null,
  getPaintingsBySeriesId: (_seriesId) => [],
  getPaintingsByTechnique: (_technique: Technique) => [],
  updatePainting: (_painting: Painting, _file?: File) =>
    new Promise<Painting>((reject) => reject),
  deletePaintingById: (_id: string) => Promise.reject(),
  createSeries: (_s: Series) => Promise.reject(),
  getSeriesById: (_id: string) => null,
  getSeriesByName: (_seriesName: string) => null,
  updateSeries: (_s: Series) => Promise.reject(),
  deleteSeriesById: (_id: string) => Promise.reject(),
});

export function ProvideAppState(props: React.PropsWithChildren<{}>) {
  const appState = useProvideAppState();

  if (appState.paintings.length > 0 && appState.series.length > 0) {
    return (
      <appStateContext.Provider value={appState}>
        {props.children}
      </appStateContext.Provider>
    );
  } else {
    return (
      <appStateContext.Provider value={appState}>
        <SuspenseFallback />
      </appStateContext.Provider>
    );
  }
}

export const useAppState = () => {
  return useContext(appStateContext);
};

async function setDownloadURLs(
  painting: Painting,
  bucketFilePaths: {
    path: string;
    fallbackPath: string;
    thumbnailPath: string;
  }
) {
  const downloadLinkPromises = [
    await storage
      .ref("images")
      .child(basename(bucketFilePaths.path))
      .getDownloadURL(),
    await storage
      .ref("images")
      .child(basename(bucketFilePaths.fallbackPath))
      .getDownloadURL(),
    await storage
      .ref("images")
      .child(basename(bucketFilePaths.thumbnailPath))
      .getDownloadURL(),
  ];
  await Promise.all(downloadLinkPromises).then((downloadLinks) => {
    painting.path = downloadLinks[0];
    painting.fallbackPath = downloadLinks[1];
    painting.thumbnailPath = downloadLinks[2];
  });
}

async function rollbackImages(painting: Painting) {
  const rollbacks: Promise<any>[] = [
    storage.refFromURL(painting.path).delete(),
  ];
  if (painting.thumbnailPath) {
    rollbacks.push(storage.refFromURL(painting.thumbnailPath).delete());
  }
  if (painting.fallbackPath) {
    rollbacks.push(storage.refFromURL(painting.fallbackPath).delete());
  }
  await Promise.all(rollbacks).catch((ignored) => {});
}

function useProvideAppState() {
  const paintingsCollection = firestore.collection("Paintings");
  const seriesCollection = firestore.collection("Series");

  const { addToast } = useToasts();

  const [appState, setAppState] = React.useState<{
    paintings: Painting[];
    series: Series[];
    cookieConsent: Consent;
    webpSupport: boolean;
  }>({
    paintings: [],
    series: [],
    cookieConsent: (() => {
      const consentValue = Cookies.get("CookieConsent");
      if (consentValue === undefined) {
        return Consent.NONE;
      } else if (consentValue === "false") {
        return Consent.FUNCTIONAL;
      } else {
        return Consent.ANALYTICS;
      }
    })(),
    webpSupport: !document.documentElement.classList.contains("no-webp"),
  });

  const updateAppState = async () => {
    console.info("Updating paintings...");
    const paintingsPromise = fetchPaintings();
    const seriesPromise = fetchSeries();

    await Promise.all([paintingsPromise, seriesPromise])
      .then((promises) => {
        setAppState({
          ...appState,
          paintings: promises[0],
          series: promises[1]
        });
        console.info("Paintings updated!");
      })
      .catch((err: any) => {
        console.warn(err);
        addToast(
          "Die Daten konnten nicht vom Server geladen werden. Versuchen Sie es später nochmal!",
          {
            appearance: "error",
            autoDismiss: false,
          }
        );
      });
  };

  // Fetch data at startup
  React.useEffect(() => {
    updateAppState();
  }, []);

  const fetchPaintings = async () => {
    return paintingsCollection.get().then((snapshot) => {
      return snapshot.docs.map((x) => x.data() as Painting);
    });
  };

  const fetchSeries = async () => {
    return seriesCollection.get().then((snapshot) => {
      return snapshot.docs.map((x) => x.data() as Series);
    });
  };

  // Painting CRRRUD

  // Create

  const createPainting = async (painting: Painting, file: File) => {
    const uniqueFileName =
      Math.abs(hashString(file.name)) +
      "_" +
      Math.round(Math.random() * 1e9) +
      extname(file.name);
    const uploadPath = `images/${uniqueFileName}`;
    try {
      await storage.ref(uploadPath).put(file, IMAGE_UPLOAD_METADATA);
      const bucketFilePaths: {
        path: string;
        fallbackPath: string;
        thumbnailPath: string;
      } = await functions
        .httpsCallable("compressPaintingImage")({ uploadPath })
        .then((result) => result.data);
      await setDownloadURLs(painting, bucketFilePaths);
      const doc = paintingsCollection.doc();
      painting.id = doc.id;
      await doc.set(painting);
      await updateAppState();
      addToast("Das Gemälde wurde erfolgreich hochgeladen.", {
        appearance: "success",
      });
      return Promise.resolve(painting);
    } catch (e) {
      await storage
        .ref(`images/${uniqueFileName}`)
        .delete()
        .catch((ignored) => {}); // Rollback image upload
      addToast(getGenericErrorMessage(e as FirebaseError), {
        appearance: "error",
      });
      return Promise.reject();
    }
  };

  // Read (by id)
  const getPaintingById = (id: string) =>
    appState.paintings.find((p) => p.id === id) || null;

  // Read (by seriesId)
  const getPaintingsBySeriesId = (seriesId: string) =>
    appState.paintings.filter((p) => p.seriesId === seriesId);

  // Read (by technique)
  const getPaintingsByTechnique = (technique: Technique) =>
    appState.paintings.filter((p) => p.techniques.includes(technique));

  // Update

  const updatePainting = async (
    painting: Painting,
    file?: File,
    suppressAppStateUpdate?: boolean
  ) => {
    let uniqueFileName = "";
    try {
      if (file) {
        uniqueFileName =
          Math.abs(hashString(file.name)) +
          "_" +
          Math.round(Math.random() * 1e9) +
          extname(file.name);
        const uploadPath = `images/${uniqueFileName}`;
        await storage.ref(uploadPath).put(file, IMAGE_UPLOAD_METADATA);
        const bucketFilePaths: {
          path: string;
          fallbackPath: string;
          thumbnailPath: string;
        } = await functions
          .httpsCallable("compressPaintingImage")({ uploadPath })
          .then((result) => result.data);
        const oldPainting = getPaintingById(painting.id)!!;
        // Rollback image upload
        await rollbackImages(oldPainting);
        await setDownloadURLs(painting, bucketFilePaths);
      }
      await paintingsCollection.doc(painting.id).set(painting);
      if (!suppressAppStateUpdate) await updateAppState();
      addToast("Das Gemälde wurde erfolgreich aktualisiert.", {
        appearance: "success",
      });
      return Promise.resolve(painting);
    } catch (e) {
      await storage
        .ref(`images/${uniqueFileName}`)
        .delete()
        .catch((ignored) => {}); // Rollback image upload
      console.error(e);
      addToast(getGenericErrorMessage(e as FirebaseError), {
        appearance: "error",
      });
      return Promise.reject();
    }
  };

  const deletePaintingById = async (id: string) => {
    try {
      const painting = await paintingsCollection.doc(id).get();
      await painting.ref.delete();
      await rollbackImages(painting.data() as Painting);
      await updateAppState();
      addToast("Das Gemälde wurde erfolgreich gelöscht.", {
        appearance: "success",
      });
      return Promise.resolve();
    } catch (e) {
      addToast(getGenericErrorMessage(e as FirebaseError), {
        appearance: "error",
      });
      return Promise.reject();
    }
  };

  // Series CRUD

  // Create

  const createSeries = async (series: Series, paintings: Painting[]) => {
    try {
      const doc = seriesCollection.doc();
      series.id = doc.id;
      await doc.set(series);
      const paintingPromises = paintings.map((p) => {
        p.seriesId = series.id;
        return updatePainting(p);
      });
      await Promise.all(paintingPromises);
      await updateAppState();
      addToast("Die Serie wurde erfolgreich erstellt.", {
        appearance: "success",
      });
      return Promise.resolve(series);
    } catch (e) {
      addToast(getGenericErrorMessage(e as FirebaseError), {
        appearance: "error",
      });
      return Promise.reject();
    }
  };

  // Read (by id)
  const getSeriesById = (id: string) => {
    return appState.series.find((s) => s.id === id) || null;
  };

  // Read (by name)
  const getSeriesByName = (seriesName: string) => {
    return appState.series.find((s) => s.name === seriesName) || null;
  };

  // Update

  const updateSeries = async (series: Series, paintings: Painting[]) => {
    try {
      await seriesCollection.doc(series.id).set(series);
      const paintingPromises = paintings.map((p) => updatePainting(p));
      await Promise.all(paintingPromises);
      await updateAppState();
      addToast("Die Serie wurde erfolgreich aktualisiert.", {
        appearance: "success",
      });
      return Promise.resolve(series);
    } catch (e) {
      addToast(getGenericErrorMessage(e as FirebaseError), {
        appearance: "error",
      });
      return Promise.reject();
    }
  };

  // Delete

  // @Pre Series is already empty

  const deleteSeriesById = async (id: string) => {
    try {
      const series = await seriesCollection.doc(id).get();
      await series.ref.delete();
      await updateAppState();
      addToast("Die Serie wurde erfolgreich gelöscht.", {
        appearance: "success",
      });
      return Promise.resolve();
    } catch (e) {
      addToast(getGenericErrorMessage(e as FirebaseError), {
        appearance: "error",
      });
      return Promise.reject();
    }
  };

  // Helper methods

  const getGenericErrorMessage = (err: FirebaseError) => {
    console.error(err.message);
    let errorMessage = "Fehler: ";
    const errorCode = err.code as
      | "cancelled"
      | "not-found"
      | "permission-denied"
      | "internal"
      | "unavailable"
      | "unauthenticated"
      | "resource-exhausted";
    switch (errorCode) {
      case "cancelled":
        errorMessage += "Die Aktion wurde abgebrochen!";
        break;
      case "not-found":
        errorMessage += "Die Ressource wurde nicht gefunden!";
        break;
      case "permission-denied":
        errorMessage += "Für dieses Vorhaben bist du nicht berechtigt!";
        break;
      case "unavailable":
        errorMessage += "Die Ressource ist nicht verfügbar.";
        break;
      case "unauthenticated":
        errorMessage += "Für dieses Vorhaben musst du authentifiziert sein!";
        break;
      case "resource-exhausted":
        console.warn("Firebase quota is exhausted...");
        errorMessage += "Oje! Etwas ist schiefgelaufen...";
        break;
      case "internal":
      default:
        errorMessage += "Oje! Etwas ist schiefgelaufen...";
        break;
    }
    return errorMessage;
  };

  return {
    paintings: appState.paintings,
    series: appState.series,
    cookieConsent: appState.cookieConsent,
    setCookieConsent: (consent: Consent) => {
      Cookies.set(
        "CookieConsent",
        consent === Consent.ANALYTICS ? "true" : "false"
      );
      const newAppState = { ...appState };
      newAppState.cookieConsent = consent;
      setAppState(newAppState);
    },
    webpSupport: appState.webpSupport,
    createPainting,
    getPaintingById,
    getPaintingsBySeriesId,
    getPaintingsByTechnique,
    updatePainting,
    deletePaintingById,
    createSeries,
    getSeriesById,
    getSeriesByName,
    updateSeries,
    deleteSeriesById,
  };
}
