import { getTechniqueByKey, Technique } from "../model/Technique";
import Painting from "../model/Painting";
import { useParams } from "react-router-dom";
import PaintingGrid from "../components/PaintingGrid";
import InnerHeader from "../components/InnerHeader";
import { useAppState } from "../providers/ProvideAppState";
import React from "react";
import { useNavigation } from "../providers/NavigationProvider";

function TechniqueDetail() {
  const appState = useAppState();
  let paintings: Painting[] = [];
  let technique: Technique | "" = "";

  const navigation = useNavigation();

  const urlTechnique = useParams<{ technique: string }>();
  let t: Technique | null = getTechniqueByKey(
    urlTechnique.technique.toUpperCase()
  );

  if (!t) {
    navigation.goHome();
  } else {
    technique = t;
    paintings = appState.paintings.filter((painting) =>
      painting.techniques.includes(t as Technique)
    );
  }

  if (paintings.length === 0) {
    console.log(
      "No paintings for " +
        urlTechnique.technique +
        " found. Redirecting home..."
    );
    navigation.goHome();
  }

  if (!paintings) {
    return <></>;
  } else {
    return (
      <>
        <InnerHeader heading={technique.toString()} showBackButton />
        <PaintingGrid paintings={paintings} />
      </>
    );
  }
}

export default TechniqueDetail;
