import Painting from "../model/Painting";
import "./PaintingGrid.css";
import PaintingCard from "./PaintingCard";
import React from "react";
import Series from "../model/Series";
import { useScrollMemory } from "../providers/ProvideScrollMemory";

function PaintingGrid(
  props: React.PropsWithChildren<{
    paintings: Painting[];
    seriesAndCallback?: {
      series: Series;
      clickCallback: (paintingId: string) => void;
    };
  }>
) {
  const scrollMemory = useScrollMemory();
  const activePainting = scrollMemory.getActivePainting();

  React.useEffect(() => {
    if (!activePainting && !props.seriesAndCallback) window.scrollTo(0, 0);
  });

  const paintings = props.seriesAndCallback
    ? props.paintings.sort((p) =>
        p.seriesId === props.seriesAndCallback?.series.id ? 0 : 1
      )
    : props.paintings;

  return (
    <div className={"container paintingGrid"}>
      {props.children}
      {paintings.map((painting) => (
        <PaintingCard
          activePainting={activePainting?.id === painting.id}
          painting={painting}
          seriesAndCallback={props.seriesAndCallback}
          key={painting.id}
        />
      ))}
    </div>
  );
}

export default PaintingGrid;
