import InnerHeader from "../components/InnerHeader";
import Mail from "../model/Mail";
import RequiredStar from "../RequiredStar";
import { init, send } from "emailjs-com";
import { useToasts } from "react-toast-notifications";
import ReCAPTCHA from "react-google-recaptcha";

import ReactGA from "react-ga4";
import { useAppState } from "../providers/ProvideAppState";
import { Consent } from "../components/Analytics";
import {ChangeEvent, MouseEvent, useEffect, useState} from "react";
import FacebookButton from "../components/FacebookButton";

init("user_wx7un5sIr6N66eF0fkWgf");

function Contact() {
  const { addToast } = useToasts();
  const { cookieConsent } = useAppState();

  useEffect(() => window.scrollTo(0, 0), []);

  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [mail, setMail] = useState<Mail>({
    name: "",
    address: "",
    subject: "",
    message: "",
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const fieldName = e.currentTarget.name as
      | "name"
      | "address"
      | "subject"
      | "message";
    const newMail = Object.assign({}, mail);
    newMail[fieldName] = e.currentTarget.value;
    setMail(newMail);
  };

  function sendMail(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const form = document.getElementsByTagName("form")[0];
    if (form.reportValidity()) {
      const button = e.currentTarget;
      button.disabled = true;
      button.classList.remove("idleButton");
      button.classList.add("loadingButton");

      const templateParams = {
        from_name: mail.name,
        from_email: mail.address,

        to_name: "Angelika",
        subject: mail.subject,
        message: mail.message,
        "g-recaptcha-response": captchaToken,
      };
      send(
        "default_service",
        "template_t5n14o9",
        templateParams,
        "user_wx7un5sIr6N66eF0fkWgf"
      )
        .then(() => {
          addToast("Gesendet!", { appearance: "success" });
          setMail({
            name: "",
            address: "",
            subject: "",
            message: "",
          });
          form.reset();
          if (cookieConsent === Consent.ANALYTICS) {
            ReactGA.event({ category: "User", action: "Sent Email" });
          }
        })
        .catch((e) => {
          addToast(
            "E-Mail konnte nicht gesendet werden! Versuchen Sie es später noch einmal!",
            { appearance: "error" }
          );
          console.error(e);
        })
        .finally(() => {
          button.disabled = false;
          button.classList.remove("loadingButton");
          button.classList.add("idleButton");
        });
    }
  }

  function handleVerification(token: string | null) {
    setCaptchaToken(token);
  }

  return (
    <>
      <InnerHeader showBackButton heading="Kontakt" />
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-xl-6 mb-4">
            <p>Über dieses Formular können Sie Kontakt mit mir aufnehmen.</p>
            <div className="p-5 shadow rounded bg-light">
              <form>
                <label className="form-label text-start w-100">
                  Name&nbsp;
                  <RequiredStar />
                </label>
                <div className={"input-group mb-3"}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Max Mustermann"
                    value={mail.name}
                    name={"name"}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <label className="form-label text-start w-100">
                  E-Mail-Adresse&nbsp;
                  <RequiredStar />
                </label>
                <div className={"input-group mb-3"}>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="max@mustermann.com"
                    value={mail.address}
                    name={"address"}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <label className="form-label text-start w-100">
                  Betreff&nbsp;
                  <RequiredStar />
                </label>
                <div className={"input-group mb-3"}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Betreff"
                    value={mail.subject}
                    name={"subject"}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <label className="form-label text-start w-100">
                  Nachricht&nbsp;
                  <RequiredStar />
                </label>
                <div className={"input-group mb-3"}>
                  <textarea
                    style={{ resize: "none" }}
                    className="form-control"
                    placeholder="Nachricht"
                    maxLength={400}
                    rows={4}
                    value={mail.message}
                    name={"message"}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div className="d-flex justify-content-center mb-3">
                  <ReCAPTCHA
                    sitekey="6Ldt8MYbAAAAAKmfYNvAYn011HKiFlgu01OBVJfe"
                    onChange={(token) => handleVerification(token)}
                  />
                </div>
                <button
                  key="save_button"
                  disabled={!captchaToken}
                  onClick={(e) => sendMail(e)}
                  className={"btn btn-success w-auto idleButton"}
                  type="submit"
                >
                  <span className="spinner-border spinner-border-sm text-white" />
                  <svg
                    id="saveIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-check-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg>
                  &nbsp;Abschicken
                </button>
              </form>
            </div>
            <FacebookButton />
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
