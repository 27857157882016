import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyBXUa37Gg5c-Ekq1qJyB1rHt6sitXySG0g",
  authDomain: "ilmago-7a84f.firebaseapp.com",
  projectId: "ilmago-7a84f",
  storageBucket: "ilmago-7a84f.appspot.com",
  messagingSenderId: "939075589335",
  appId: "1:939075589335:web:1687ba956f51cd0f748c92",
  measurementId: "G-CDVP9MGZM9",
};

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();

const storage = firebase.storage();

const auth = firebase.auth();

const functions = firebase.app().functions("europe-west1");

interface FirebaseUser extends firebase.User {

}

interface FirebaseError extends firebase.FirebaseError {

}

interface UploadMetadata extends firebase.storage.UploadMetadata {

}

export { firestore, storage, auth, functions, firebase as default };
export type { UploadMetadata, FirebaseUser, FirebaseError };
