import TechniqueOverview from "../components/TechniqueOverview";
import InnerHeader from "../components/InnerHeader";
import { useAuth } from "../providers/ProvideAuth";
import { useScrollMemory } from "../providers/ProvideScrollMemory";
import React from "react";
import BottomLinks from "../components/BottomLinks";

function Home() {
  const auth = useAuth();
  const scrollMemory = useScrollMemory();
  React.useEffect(() => scrollMemory.setActivePainting(null));
  React.useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <h1 className="pageTitle">Farben Formen Fantasien</h1>
      {!auth.user?.isAnonymous ? <InnerHeader showAdminButtons /> : <></>}
      <div className="container mt-3">
        <h2 className="my-4">Willkommen auf meiner digitalen Bilderseite!</h2>
        <p style={{ textAlign: "justify" }}>
          Auf dieser werden Ihnen reichhaltige Farbkompositionen,
          Schwarz-weiß-Darstellungen, sowie bekannte und außergewöhnliche
          Formen, im Zusammenhang mit teilweise fantastisch-surrealen Elementen
          begegnen. Sie spiegelt meine Malaktivitäten der letzten fünf Jahre
          wider. Spricht Sie eines der Bilder an, so haben Sie die Möglichkeit
          es genauer zu betrachten und dabei einige Details zu erfahren.
          Anschließend freue ich mich über eine Kontaktaufnahme.
        </p>
      </div>
      <BottomLinks />
      <TechniqueOverview />
    </>
  );
}

export default Home;
