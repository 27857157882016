import "./InnerHeader.css";
import React from "react";
import {Link} from "react-router-dom";
import {useAuth} from "../providers/ProvideAuth";
import {Helmet} from "react-helmet";
import {useNavigation} from "../providers/NavigationProvider";

function InnerHeader(
  props: React.PropsWithChildren<{
    showBackButton?: boolean;
    showAdminButtons?: boolean;
    showEditButton?: boolean;
    callbacks?: {
      delete?: () => Promise<void>;
      cancel?: () => void;
      save?: () => Promise<void>;
    };
    heading?: string;
    useHeadingAsPageTitleOnly?: boolean;
  }>
) {
  const auth = useAuth();
  const navigation = useNavigation();

  function runDatabaseAction(
    e: React.MouseEvent<HTMLButtonElement>,
    actionCallback: () => Promise<void>
  ) {
    const button = e.currentTarget;
    button.classList.remove("idleButton");
    button.classList.add("loadingButton");
    button.disabled = true;
    actionCallback().finally(() => {
      button.classList.remove("loadingButton");
      button.classList.add("idleButton");
      button.disabled = false;
    });
  }

  return (
    <>
      {props.heading ? (<>
          <Helmet>
            <title>Farben Formen Fantasien - {props.heading}</title>
          </Helmet>
        </>
      ) : (
        <></>
      )}
      <div className={"container mb-4 innerHeader"}>
        <div className="d-flex align-items-center">
          {props.showBackButton ? (
            <button
              onClick={navigation.goBack}
              className={"btn btn-primary w-auto"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-left-circle"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                />
              </svg>
            </button>
          ) : (
            <></>
          )}
          {props.heading && !props.useHeadingAsPageTitleOnly ? (
            <h2 className="w-auto ms-3 mb-0">{props.heading}</h2>
          ) : (
            <></>
          )}
          {props.showEditButton ? (
            <Link
              key="edit_button"
              to={navigation.currentPath + "/edit"}
              className="ms-3"
            >
              <button className={"btn btn-primary w-auto"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                  <path
                    fillRule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                  />
                </svg>
              </button>
            </Link>
          ) : (
            <></>
          )}
          {(() => {
            if (!props.callbacks) {
              if (props.showAdminButtons) {
                return (
                  <div className="w-auto" style={{marginLeft: "auto"}}>
                    <Link to="/painting/new">
                      <button type="button" className="btn btn-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-easel"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M8 0a.5.5 0 0 1 .473.337L9.046 2H14a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1.85l1.323 3.837a.5.5 0 1 1-.946.326L11.092 11H8.5v3a.5.5 0 0 1-1 0v-3H4.908l-1.435 4.163a.5.5 0 1 1-.946-.326L3.85 11H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h4.954L7.527.337A.5.5 0 0 1 8 0zM2 3v7h12V3H2z"/>
                        </svg>
                        &nbsp;Neues Gemälde
                      </button>
                    </Link>
                    <Link to="/series/new">
                      <button type="button" className="btn btn-primary ms-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          className="bi bi-folder2"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v7a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 12.5v-9zM2.5 3a.5.5 0 0 0-.5.5V6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5zM14 7H2v5.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V7z"/>
                        </svg>
                        &nbsp;Neue Serie
                      </button>
                    </Link>
                    <button
                      type="button"
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        auth.logout().catch(() => {
                        });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-box-arrow-left"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                        />
                      </svg>
                      &nbsp;Logout
                    </button>
                  </div>
                );
              } else {
                return <></>;
              }
            } else {
              const buttons = [];
              if (props.callbacks.delete) {
                buttons.push(
                  <button
                    key="delete_button"
                    onClick={(e) =>
                      runDatabaseAction(e, props.callbacks!!.delete!!)
                    }
                    className={"btn btn-warning w-auto idleButton"}
                  >
                    <span className="spinner-border spinner-border-sm text-white"/>
                    <svg
                      id="deleteIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-trash"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path
                        fillRule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                      />
                    </svg>
                    &nbsp;Löschen
                  </button>
                );
              }
              if (props.callbacks.cancel) {
                buttons.push(
                  <button
                    key="cancel_button"
                    onClick={props.callbacks.cancel}
                    className={"btn btn-danger w-auto ms-2"}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-x-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                    &nbsp;Abbrechen
                  </button>
                );
              }
              if (props.callbacks.save) {
                buttons.push(
                  <button
                    key="save_button"
                    onClick={(e) =>
                      runDatabaseAction(e, props.callbacks!!.save!!)
                    }
                    className={"btn btn-success w-auto ms-2 idleButton"}
                  >
                    <span className="spinner-border spinner-border-sm text-white"/>
                    <svg
                      id="saveIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                    </svg>
                    &nbsp;Speichern
                  </button>
                );
              }
              return <div className="w-auto ms-3">{buttons}</div>;
            }
          })()}
        </div>
      </div>
    </>
  );
}

export default InnerHeader;
