export enum Technique {
  OIL = "Öl/Pastellkreide",
  INK = "Tusche",
  WATERCOLOR = "Aquarell",
  PENCIL = "Bleistift/Farbstift",
  MIXED = "Mixed Media",
  COLLAGE = "Collage/Schnittbild",
}

export function getTechniqueByString(x: string): Technique | null {
  for (const [, technique] of Object.entries(Technique)) {
    if (technique.toString() === x) return technique;
  }
  return null;
}

export function getTechniqueByKey(x: string): Technique | null {
  for (const [descriptor, technique] of Object.entries(Technique)) {
    if (descriptor === x) return technique;
  }
  return null;
}
