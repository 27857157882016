import React, { useContext } from "react";
import Painting from "../model/Painting";

export function ProvideScrollMemory(props: React.PropsWithChildren<{}>) {
  const scrollMemory = useProvideScrollMemory();

  let scrollToTopButton: HTMLButtonElement | null;

  React.useEffect(() => {
    scrollToTopButton = document.getElementById(
      "scrollToTopButton"
    ) as HTMLButtonElement;
    window.addEventListener("scroll", (_e) => scrollWatcher());
    return window.removeEventListener("scroll", (_e) => scrollWatcher());
  }, []);

  function scrollWatcher() {
    if (window.location.pathname !== "/") {
      if (scrollToTopButton) {
        if (
          document.body.scrollTop > 20 ||
          document.documentElement.scrollTop > 20
        ) {
          scrollToTopButton.style.visibility = "visible";
          scrollToTopButton.style.opacity = "1";
        } else {
          scrollToTopButton.style.visibility = "hidden";
          scrollToTopButton.style.opacity = "0";
        }
      }
    }
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <scrollMemoryContext.Provider value={scrollMemory}>
      {props.children}
      <button
        onClick={(_e) => scrollToTop()}
        id="scrollToTopButton"
        title="Go to top"
        className="btn btn-primary"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-arrow-up-circle"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
          />
        </svg>
      </button>
    </scrollMemoryContext.Provider>
  );
}

export const useScrollMemory = () => {
  return useContext(scrollMemoryContext);
};

function useProvideScrollMemory() {
  const [activePainting, setActivePainting] = React.useState<Painting | null>(
    null
  );

  return {
    setActivePainting: (painting: Painting | null) => {
      setActivePainting(painting);
    },
    getActivePainting: () => activePainting,
  };
}

const scrollMemoryContext = React.createContext<
  ReturnType<typeof useProvideScrollMemory>
>({
  setActivePainting: (_p: Painting | null) => undefined,
  getActivePainting: () => null,
});
