import InnerHeader from "../components/InnerHeader";
import React from "react";
import "./About.css";
import { Link } from "react-router-dom";

function About() {
  React.useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <InnerHeader heading="Über mich" showBackButton />
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-xl-4 mb-4">
            <picture>
              <source srcSet={"/angelika.webp"} type="image/webp" />
              <img
                alt="Angelika Suppan"
                src={"/angelika.jpg"}
                style={{ maxHeight: "30rem" }}
                className={"paintingShadow"}
              />
            </picture>
            <div className="w-50 m-auto mt-1">
              <span className="text-primary fst-italic">
                „Unbekanntem auf den Grund gehen, Bekanntes verfremden.“
              </span>
            </div>
          </div>
          <div className="col-xl-7" style={{ textAlign: "justify" }}>
            <p className="aboutText">
              „Am Anfang war das Wort“, heißt es in der Alten Schrift – was mich
              betrifft war am Anfang meiner malerischen Entwicklung eine Form.
              Diese erste Kinderzeichnung erinnerte entfernt an ein großes B.
              Vielleicht ein sehr bequemer Couchsessel?
              <br /> Ein Couchsessel in Blau, womit wir bei einer meiner
              Lieblingsfarben wären. Vielleicht war dieses Möbelstück, das oft
              sinnbildlich für die Psychotherapie steht, ein erster Hinweis auf
              mein späteres Studium der Psychologie? In dieses ließ sich meine
              zweite Leidenschaft, die Musik, mit der Zeit trefflich
              integrieren, was für mich nichts anderes als ein großer Glücksfall
              war. Nach einem „Ausflug“ in die Medizin gründete ich 2015 meine{" "}
              <a
                href="https://mobilemusikschule.at"
                target="_blank"
                className="blueLink"
                rel="noreferrer"
              >
                Mobile Musikschule
              </a>
              , ohne dabei meine Maltätigkeit einzuschränken. Im Gegenteil
              begann zu diesem Zeitpunkt eine fruchtbare Phase, die schließlich
              in vier erfolgreiche Ausstellungen in Wien mündete.
              <br /> Inzwischen lebe ich großteils in Tirol, was ich für meine
              Kunst als sehr bereichernd empfinde. Die Fantasie ist ja zum Glück
              nicht ortsgebunden, sondern ein zuverlässiger Begleiter, der mich
              nur selten im Stich gelassen hat. Wenn auch die meisten Bilder,
              unabhängig von der Technik, in Farbe sind, so lasse ich diese auch
              gerne einmal beiseite, wie in{" "}
              <Link className="blueLink" to={"/painting/BiSc9GF6UZi1bo8ScAcy"}>
                ‚Summershower‘
              </Link>{" "}
              (Bleistift) beziehungsweise reduziere ich mich auf farbliche
              Sparsamkeit, beispielsweise in den Serien{" "}
              <Link className="blueLink" to={"/series/5AsiD6KzYNevniZPJnTA"}>
                ‚Life of the Samurai‘
              </Link>{" "}
              (Tusche, Farbstift) und{" "}
              <Link className="blueLink" to={"/series/nI8Cvrl0MAMmwWBrNpF6"}>
                ‚Teatro Botanico‘
              </Link>{" "}
              (Tusche, Goldlackstift).
              <br /> Nun hat der Jüngere meiner beiden Söhne dem Ergebnis meiner
              intensiven Maltätigkeit der letzten Jahre die digitale Form
              gegeben, die es mir nun erlaubt, meine Kunst einem größeren Kreis
              an Interessierten bekannt zu machen.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
