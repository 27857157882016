import React, {useContext, useState} from "react";
import {useToasts} from "react-toast-notifications";
import {auth, FirebaseUser} from "../firebase/firebase";
import firebase from "firebase/app";

const authContext = React.createContext<{
  user: FirebaseUser | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}>({
  user: null,
  login: (_u: string, _p: string) => new Promise<void>((reject) => reject()),
  logout: () => new Promise<void>((reject) => reject()),
});

export function ProvideAuth(props: React.PropsWithChildren<{}>) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>{props.children}</authContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const {addToast} = useToasts();
  const [user, setUser] = useState<firebase.User | null>(null);

  React.useEffect(() => {
    return auth.onAuthStateChanged(
      (user: firebase.User | null) => {
        setUser(user);
      }
    );
  }, []);

  React.useEffect(() => {
    if (!user) loginAnonymousUser();
  }, [user]);

  const loginAnonymousUser = async () => {
    return auth
      .signInAnonymously()
      .then(() => console.info("Anonymous sign-in successful!"))
      .catch((error: firebase.FirebaseError) => {
        console.error("Anonymous sign-in failed!", error);
      });
  };

  const login = async (email: string, password: string) => {
    return new Promise<void>((resolve, reject) => {
      auth
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          addToast("Login erfolgreich!", {
            appearance: "success",
          });
          resolve();
        })
        .catch((error: firebase.FirebaseError) => {
          console.error(error);
          const errorCode = error.code;
          if (
            errorCode === "auth/wrong-password" ||
            errorCode === "auth/user-not-found" ||
            errorCode === "auth/invalid-email"
          ) {
            addToast(
              "Login fehlgeschlagen! Überprüfe die Zugangsdaten und versuche es erneut!",
              {
                appearance: "error",
              }
            );
          } else {
            addToast(
              "Ein unbekannter Fehler ist aufgetreten, versuche es später noch einmal!",
              {
                appearance: "error",
              }
            );
          }
          reject();
        });
    });
  };

  const logout = async () => {
    return new Promise<void>((resolve, reject) =>
      auth
        .signOut()
        .then(() => {
          addToast("Logout erfolgreich. Bis bald!", {
            appearance: "success",
          });
          resolve();
        })
        .catch((error: firebase.FirebaseError) => {
          console.error(error);
          addToast("Logout fehlgeschlagen.", {
            appearance: "error",
          });
          reject();
        })
    );
  };

  return {
    user,
    login,
    logout,
  };
}
