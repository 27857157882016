import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

export function ProvideNavigation(props: React.PropsWithChildren<{}>) {
  const navigation = useProvideNavigation();
  return (
    <navigationContext.Provider value={navigation}>
      {props.children}
    </navigationContext.Provider>
  );
}

export const useNavigation = () => {
  return useContext(navigationContext);
};

function useProvideNavigation() {
  const [prevLocation, setPrevLocation] = React.useState<string>("");
  const history = useHistory();
  React.useEffect(() => {
    return history.listen((location) => {
      setPrevLocation(location.pathname);
    });
  }, []);

  const goHome = () => {
    history.push("/");
  };

  return {
    goBack: () => {
      if (!prevLocation) {
        goHome();
      } else {
        history.goBack();
      }
    },
    goHome,
    currentPath: history.location.pathname,
  };
}

const navigationContext = React.createContext<
  ReturnType<typeof useProvideNavigation>
>({
  goBack: () => null,
  goHome: () => null,
  currentPath: "",
});
