import { Redirect, Route, RouteProps } from "react-router";
import { useAuth } from "../providers/ProvideAuth";
import React from "react";

function ProtectedRoute({ ...routeProps }: RouteProps) {
  const auth = useAuth();
  if (!auth.user?.isAnonymous) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={{ pathname: "/login" }} />;
  }
}

export default ProtectedRoute;
