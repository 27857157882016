import "./SeriesOverview.css";
import SeriesPanel from "../components/SeriesPanel";
import InnerHeader from "../components/InnerHeader";
import { useAppState } from "../providers/ProvideAppState";
import React from "react";
import { useScrollMemory } from "../providers/ProvideScrollMemory";

function SeriesOverview() {
  const appState = useAppState();
  const scrollMemory = useScrollMemory();
  React.useEffect(() => window.scrollTo(0, 0), []);
  React.useEffect(() => scrollMemory.setActivePainting(null));
  return (
    <>
      <InnerHeader heading="Serien" showBackButton />
      <div className={"container seriesGrid my-5"}>
        {appState.series.map((series) => (
          <SeriesPanel series={series} key={series.name} />
        ))}
      </div>
    </>
  );
}

export default SeriesOverview;
