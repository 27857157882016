import Series from "../model/Series";
import Painting from "../model/Painting";
import { Link } from "react-router-dom";
import "./SeriesPanel.css";
import { useAppState } from "../providers/ProvideAppState";
import React from "react";

function SeriesPanel(props: { series: Series }) {
  const appState = useAppState();
  const seriesPaintings = appState.getPaintingsBySeriesId(props.series.id);
  const paintingCount = Math.min(seriesPaintings.length, 3);

  let paintings: Painting[] = [];
  for (let i = 0; i < paintingCount; i++) {
    paintings.push(seriesPaintings[i]);
  }

  const thumbChildren = [];
  for (let i = 0; i < paintings.length; i++) {
    const backgroundImage = `url(${
      appState.webpSupport
        ? paintings[i].thumbnailPath
        : paintings[i].fallbackPath
    })`;
    const zIndex = paintings.length - i;
    thumbChildren.push(
      <div
        className={`paintingShadow ${
          i % 3 === 0 ? "thumb1Area" : i % 3 === 1 ? "thumb2Area" : "thumb3Area"
        }`}
        style={{ backgroundImage, zIndex }}
        key={`${props.series.name}_thumb${i}`}
      />
    );
  }

  return (
    <Link to={`/series/${props.series.id}`}>
      <div className="panel w-100">{thumbChildren}</div>
      <div
        className="paintingCardTitle"
        style={{ width: "100%", textAlign: "center" }}
      >
        <span>{props.series.name}</span>
      </div>
    </Link>
  );
}

export default SeriesPanel;
