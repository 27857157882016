import "./TechniqueOverview.css";
import { Technique } from "../model/Technique";
import { Link } from "react-router-dom";
import { useAppState } from "../providers/ProvideAppState";
import React from "react";

function TechniqueOverview() {
  const appState = useAppState();

  const showcasePaintingIds: string[][] = [
    [Technique.OIL, "IJGB70uv5VpVGJP4VULN"],
    [Technique.INK, "dgzc4q1bkJ1SwmpxZcrZ"],
    [Technique.WATERCOLOR, "5yqwfaXlOAIprFYptddH"],
    [Technique.PENCIL, "vVzFM5qEyoc9uvrQDvQJ"],
    [Technique.MIXED, "CnqIhQJGqHZ0Mu8ClTNw"],
    [Technique.COLLAGE, "cLkfz0OE9e2tmvJFDkND"],
    ["series", "1zjgk2mBaaAvgLJdZNOM"],
  ];

  let techniqueCols: JSX.Element[] = [];

  for (const [descriptor, technique] of Object.entries(Technique).sort((x, y) =>
    x[1] > y[1] ? 1 : 0
  )) {
    const showcasePaintingId = showcasePaintingIds.find(
      (techniqueAndId) =>
        techniqueAndId[0] === technique && techniqueAndId[1].length > 0
    );
    if (showcasePaintingId) {
      const showCasePainting =
        appState.getPaintingById(showcasePaintingId[1]) ||
        appState.paintings[0];
      techniqueCols.push(
        <Link
          to={`/paintings/${descriptor.toLowerCase()}`}
          key={descriptor.toLowerCase()}
        >
          <div className={"outer"}>
            <div className={"top"}>
              <h1 className="specialHeading vertical">
                {technique.toString()}
              </h1>
            </div>
            <div
              className={"below"}
              style={{
                backgroundImage: `url(${
                  appState.webpSupport
                    ? showCasePainting.thumbnailPath
                    : showCasePainting.fallbackPath
                })`,
                backgroundSize: "cover",
              }}
            />
          </div>
        </Link>
      );
    } else {
      techniqueCols.push(
        <div
          className={"outer border border-secondary"}
          key={descriptor.toLowerCase()}
        >
          <div className={"top"}>
            <h1 className="specialHeading vertical">{technique.toString()}</h1>
          </div>
          <div className={"below"} />
        </div>
      );
    }
  }

  const seriesShowcasePaintingId = showcasePaintingIds.find(
    (techniqueAndId) =>
      techniqueAndId[0] === "series" && techniqueAndId[1].length > 0
  );

  if (seriesShowcasePaintingId) {
    const seriesShowcasePainting =
      appState.getPaintingById(seriesShowcasePaintingId[1]) ||
      appState.paintings.filter((p) => p.seriesId)[0];
    techniqueCols.push(
      <Link to={`/series`} className={"specialTechnique"} key={"series"}>
        <div className={"outer"}>
          <div className={"top special"}>
            <h1 className={"specialHeading"}>Serien</h1>
          </div>
          <div
            className={"below belowSpecial"}
            style={{
              backgroundImage: `url(${seriesShowcasePainting.path})`,
              backgroundSize: "contain",
            }}
          />
        </div>
      </Link>
    );
  } else {
    techniqueCols.push(
      <div
        className={"outer border border-secondary specialTechnique"}
        key={"series"}
      >
        <div className={"top"}>
          <h1 className={"specialHeading special"}>{"Serien"}</h1>
        </div>
        <div className={"below"} />
      </div>
    );
  }

  return <div className="container-sm techniqueGrid my-4">{techniqueCols}</div>;
}

export default TechniqueOverview;
